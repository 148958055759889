<template>
  <div class="container">
<!--    筛选表单-->
    <formCom :formList="formList" :inline="true"
      @conrtolTip="conrtolTip"
      @screen = 'queryQrCode'
      @createQrCode="createQRCodeDialog = true" />
<!--    查询表格-->
    <tableCom
      :data.sync="data"
      :loading.sync="loading"
      :header="tableHeader"
      :total.sync="total"
      @verificat="verificat"
      @active="active"
      @activation="activation"
      @cancelActivation="cancelActivation"
      @download="download"
      @changePageSize="changePageSize"
      @changePage="changePage" />
<!--    弹出的生产二维码的表单-->
    <el-dialog
      title="二维码生成"
      :visible.sync="createQRCodeDialog"
      width="30%">
      <formCom :formList="flyform" @createQrCode="createQRCode"/>
    </el-dialog>
<!--    操作提示-->
    <el-dialog
      title="操作提示"
      :visible.sync="controlTip"
      width="60%">
      <div style="text-align: center;">
        <img style="max-width: 100%" src="@/assets/img/OwnWorkbench/control.png" alt="">
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content: space-between;align-items: center;">
        <el-link type="primary">看不明白？点击前往帮助文档</el-link>
        <el-button type="primary" @click="controlTip = false">我已知晓</el-button>
        <el-checkbox @change="changeTip" v-model="checked">不再提示</el-checkbox>
      </span>
    </el-dialog>
<!--    防伪验真弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" width="50%">
      <template v-if="isVerify">
        <div >
          <el-switch
            v-model="openVerify"
            @change="changeVerify"
            :active-text="`已选中${currentLine.needVerifyCode ? '' : '不'}需要验证码`"
            :inactive-text="openVerify ? '开启' : '未开启'">
          </el-switch>
<!--          :active-text="`已选中${openVerify ? '' : '不'}需要验证码`"-->
        </div>
        <div class="" style="white-space: nowrap;display: flex;justify-content: center">
          <img v-if="currentLine.needVerifyCode" src="@/assets/img/OwnWorkbench/verify.png" alt="" class="" style="width: 32%;margin-left: 1%">
          <img src="@/assets/img/OwnWorkbench/verify_first.png" alt="" class="" style="width: 32%;margin-left: 1%">
          <img src="@/assets/img/OwnWorkbench/verify_nofirst.png" alt="" class="" style="width: 32%;margin-left: 1%">
        </div>
      </template>
      <template v-else>
        <el-divider content-position="left">我的活动</el-divider>
        <div style="display: flex;">
          <div class="activity-create" style="margin-right: 20px;" @click="toCreateActive">
            <i class="el-icon-circle-plus-outline"></i>
            <p>创建活动</p>
          </div>
          <template v-for="(item, index) in activeList">
            <template v-if="item.id == currentLine.activityId">
              <active :item="item" :key="index" :id="currentLine.activityId" @queryActive="queryActive" @relation="relation"/>
            </template>
          </template>
        </div>
        <el-divider content-position="left">我创建的活动</el-divider>
        <div v-if="activeList.length > 0">
          <template v-for="(item, index) in activeList">
            <active :item="item" :key="index" :id="currentLine.activityId || 1" @queryActive="queryActive" @relation="relation"/>
          </template>
        </div>
        <div v-else>
          <div style="margin-top: 130px">
            <listNull text="你还没有创建活动呢~" />
          </div>
        </div>
        <!--<active :activeList="activeList"-->
                <!--title="当前已关联的活动"-->
                <!--:relation="true"-->
                <!--:activityId="currentLine.activityId"-->
                <!--@relation="relation"/>-->
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDialog = false">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showBagList" title="请选择需要下载的码包">
      <el-radio-group v-model="downloadRadio">
        <template v-for="(item, index) of bagList">
          <div :key="index" style="line-height: 50px">
            <el-radio :label="item.id">
              生码量：{{item.workQty}}， 生码时间：{{item.createdTime}}
            </el-radio>
          </div>
        </template>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadEvent">确 定</el-button>
        <el-button @click="showBagList = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formCom from '@/components/formCom/form.vue'
import tableCom from '@/components/tableCom/table.vue'
import active from '@/components/activeList/active.vue'
import listNull from '@/components/listNull.vue'
import { formList, flyform, tableHeader } from './js/qrManageMent.js'
import {
  generateSignleCodes,
  queryCodeByPage,
  activate,
  unActivate,
  listBagList,
  download,
  updateIfOpenVerify,
  updateIfOpenActivity
} from '@/api/qrCode.js'
import { queryAll } from '@/api/active.js'
export default {
  components: {
    formCom, tableCom, active, listNull
  },
  data () {
    return {
      // 表单配置 表格配置相关
      formList: formList,
      flyform: flyform,
      tableHeader: tableHeader,
      // 弹出框相关
      createQRCodeDialog: false,
      controlTip: false,
      checked: false,
      showDialog: false,
      openVerify: false,
      showBagList: false,
      loading: true,
      isVerify: true, // 判断弹窗内容显示防伪验真或营销活动
      noTip: '', // 不在提示
      dialogTitle: '',
      activeList: [], // 弹窗活动列表
      activeTotal: 0, // 活动总数
      bagList: [], // 下载码包列表
      downloadRadio: '', // 选择下载码包
      // 表格数据、操作相关
      data: [],
      query: {},
      total: 0,
      limit: 10,
      pageIndex: 1,
      interval: null,
      result: false,
      currentLine: {} // 当前选中
    }
  },
  mounted () {
    this.queryQrCode()
    this.interval = setInterval(()=>{
      this.queryQrCode()
    }, 3000)
    let noTip = localStorage.getItem('noTip')
    if(noTip == 'true'){
      this.noTip = true
      this.checked = true
    }else{
      this.noTip = false
      this.checked = false
    }
  },
  beforeDestroy(){
    clearInterval(this.interval)
    this.interval = null
    this.$bus.$off('countTime')
  },
  methods: {
    queryQrCode (data) {
      if(data){
        this.query = data
      }
      this.loading = true
      queryCodeByPage({
        beginCreatedTime: this.query ? this.query.beginCreatedTime ? this.query.beginCreatedTime[0] : '' : '',
        endCreatedTime: this.query ? this.query.beginCreatedTime ? this.query.beginCreatedTime[1] : '' : '',
        status: this.query ? this.query.status ? this.query.status : '' : '',
        limit: this.limit,
        page: this.pageIndex
      }).then(res => {
        this.loading = false
//        this.result = false
        if (res.code === 0) {
          this.data = res.data.list
          this.total = res.data.totalCount
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    createQRCode (data) {
      if(this.result){
          return
      }
      this.result = true
      if(!(data.qty > 0)){
        this.$message.error('请输入正确的生码数量')
        this.result = false
        return
      }
      const timeStamp = new Date().getTime()
      generateSignleCodes({ ...data,caption: timeStamp, verifyCodeLen: 6 }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '成功'
          })
          this.createQRCodeDialog = false
          if(localStorage.getItem('noTip') !== 'true'){
            this.controlTip = true
          }
          this.queryQrCode()
          setTimeout(()=>{
              this.result = false
          },2000)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    changeTip () {
        localStorage.setItem('noTip',this.checked)
    },
    conrtolTip () { // 弹出操作提示
      this.controlTip = true
    },
    activation (row) { // 激活
      activate({ thirdId: row.thirdId }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '激活成功'
          })
          this.queryQrCode()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    cancelActivation (row) { // 取消激活
      unActivate({ thirdId: row.thirdId }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '取消激活成功'
          })
          this.queryQrCode()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    download (row) { // 下载码包
      console.log(row)
      listBagList({ batchId: row.thirdId }).then(res => {
        console.log(res)
        if (res.data) {
          this.showBagList = true
          this.bagList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    relation (activityID, ifOpenActivity) { // 关联活动
      updateIfOpenActivity({
        id: this.currentLine.id,
        ifOpenActivity: ifOpenActivity,
        activityId: activityID
      }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: ifOpenActivity === 1 ? '关联成功' : '取消关联成功'
          })
          this.showDialog = false
          this.queryQrCode()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    downloadEvent () {
        console.log(this.downloadRadio)
      if(!this.downloadRadio){
        this.$message.error('请选择码包')
        return
      }
      const url = download + '?id=' + this.downloadRadio + '&token=' + localStorage.getItem('token')
      window.open(url)
      this.showBagList = false
    },
    changePageSize (val) { // 分页1
      this.limit = val
      this.queryQrCode()
    },
    changePage (val) { // 分页2
      this.pageIndex = val
      this.queryQrCode()
    },
    // 创建活动
    toCreateActive () {
      this.$router.push({ name: 'MarketingActivities' })
    },
    verificat (data) { // 打开防伪验真弹窗
      console.log(data)
      this.currentLine = data
      this.dialogTitle = `防伪验真（${data.ifOpenVerify ? '已开启' : '未开启'})`
      this.openVerify = data.ifOpenVerify === 1
      this.showDialog = true
      this.isVerify = true
    },
    changeVerify (val) { // 开启关闭防伪验真
      updateIfOpenVerify({
        id: this.currentLine.id,
        ifOpenVerify: val ? 1 : 0,
        verifyId: this.currentLine.verifyId
      }).then(res => {
        if (res.code === 0) {
          this.queryQrCode()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    active (data) {
      this.currentLine = data
      this.queryActive()
      this.dialogTitle = `营销活动（${data.ifOpenActivity ? '已开启' : '未开启'})`
      this.showDialog = true
      this.isVerify = false
    },
    queryActive () {
      queryAll({
        pageNO: 1,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          this.activeList = res.data.list
          this.activeTotal = res.data.total
          this.setStatus()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
     // 判断状态
  //  setStatus () {
  //    // 1.进行中 2.未发布 3.未开始 4.已结束 5.倒计时
  //   let currentTime
  //   this.activeList.forEach(item => {
  //     currentTime = new Date().getTime()
  //     if(item.isEnable && currentTime > new Date(item.beginTime).getTime() && currentTime < new Date(item.endTime).getTime()) {
  //       item.status = 1
  //     }else if(!item.isEnable) {
  //       item.status = 2
  //     }else if(item.isEnable && currentTime < new Date(item.beginTime).getTime() && new Date(item.beginTime).getTime() - currentTime > 600000) {
  //       item.status = 3
  //     }else if(item.isEnable && currentTime > new Date(item.endTime).getTime()) {
  //       item.status = 4
  //     }else if(item.isEnable && currentTime < new Date(item.beginTime).getTime() && new Date(item.beginTime).getTime() - currentTime <= 600000) {
  //     item.status = 5
  //     this.$bus.$emit('countTime', new Date(item.beginTime).getTime() - currentTime)
  //     // this.$bus.$emit('countTime', 10000)
  //   }
  //   })
  //  }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    margin: 0px 100px 0 20px;
    padding-top: 20px;
  }
  .activeList{
    width: 200px;
    height: 200px;
    text-align: center;
    line-height: 200px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 20px 0
  }
  .activity-create {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ccc;
    width: 280px;
    height: 280px;
    border-radius: 20px;
    border: 1px solid #ccc;
    transition: all .2s linear;
    cursor: pointer;
    &:hover{
      box-shadow: 0 4px 12px 0 rgba(0,0,0,.08);
      transform: translate3d(0,-8px,0);
    }
    i {
      font-size: 60px;
    }
    p {
      font-size: 14px;
      margin-top: 10px;
    }
  }
</style>
