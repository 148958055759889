import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
const http = new Request(process.env.VUE_APP_URL)
// 二维码生成接口
export const generateSignleCodes = params => {
  return http.post('/cube_web_api/rawcode/coderelation/generateSignleCodes', params)
}
// 分页查询二维码批次
export const queryCodeByPage = params => {
  return http.post('/cube_web_api/rawcode/coderelation/queryCodeByPage', params)
}
// 激活码包
export const activate = params => {
  return http.post('/cube_web_api/rawcode/coderelation/activate', params)
}
// 取消激活码包
export const unActivate = params => {
  return http.post('/cube_web_api/rawcode/coderelation/unActivate', params)
}
// 下载码包查询
export const listBagList = params => {
  return http.post('/cube_web_api/rawcode/coderelation/listBagList', params)
}
// 下载指定码包
let url = ''
if (location.protocol == 'https:') {
  url = 'https://' + location.hostname + '/'
} else {
  url = 'https://cubewebapi.51ylk.com/'
}
export const download = url + 'cube_web_api/rawcode/coderelation/download'
// 开启关闭防伪验真
export const updateIfOpenVerify = params => {
  return http.post('/cube_web_api/rawcode/coderelation/updateIfOpenVerify', params)
}
// 开启关闭关联活动
export const updateIfOpenActivity = params => {
  return http.post('/cube_web_api/rawcode/coderelation/updateIfOpenActivity', params)
}

// ----------------无限二维码----------------------
// 联系我们
export const saveContact = params => {
  return http.post('/cube_web_api/sys/consulting/save', params)
}
