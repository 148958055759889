<template>
  <div>
    <el-table :data="data" v-loading="loading" border style="width: 100%">
      <el-table-column
        align="center"
        v-for="(item, index) of header"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
      >
        <template slot-scope="scope">
          <div v-if="item.type === 'null'">{{ scope.row[item.prop] }}</div>
          <div v-if="item.type === 'image'">
            <img :src="scope.row[item.prop]" alt="">
          </div>
          <div v-if="item.type === 'time'">{{ (scope.row[item.prop], scope.row[item.format] | fTime) }}</div>
          <div v-if="item.type === 'phone'">{{ scope.row[item.prop] | fPhone }}</div>
          <div v-if="item.type === 'link'">
            <a v-if="scope.row[item.prop]"
                     :href="scope.row[item.prop]"
                     >{{ scope.row[item.prop]}}</a>
            <span v-else>无</span>
          </div>
          <div v-if="item.type === 'progress'">
            <div v-if="scope.row[item.field] === 100">
              已完成
            </div>
            <div v-else>
              <el-progress :text-inside="true" :stroke-width="15" :percentage="scope.row[item.field]"></el-progress>
            </div>
<!--            <template v-for="(pro, i) of scope.row[item.field]">-->

<!--            </template>-->
          </div>
          <div v-if="item.type === 'status'">
            <template v-if="item.isLabel">
              <el-tag :type="scope.row[item.prop], item.list, 'type' | fStatus">{{ scope.row[item.prop], item.list | fStatus }}</el-tag>
            </template>
            <template v-else-if="item.isLink">
              <el-link type="primary" @click="$emit(item.event, scope.row)">{{ scope.row[item.prop], item.list | fStatus }}</el-link>
            </template>
            <template v-else-if="item.handle">
              {{ scope.row[item.prop], item.list | fStatus }}
            </template>
            <template v-else>{{ scope.row[item.prop] }}</template>
          </div>
          <div v-if="item.type === 'control'">
            <template v-for="(btn,i) of item.list">
              <template v-if="btn.exclude">
                <el-button v-if="btn.value.includes(scope.row[btn.field])"
                  size="mini" :type="btn.mold" :key="i" @click="$emit(btn.event, scope.row, btn.args)">
                  {{ btn.text }}
                </el-button>
              </template>
              <template v-else>
                <el-button size="mini"
                :type="btn.mold" :key="i" @click="$emit(btn.event, scope.row)">{{ btn.text }}</el-button>
              </template>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 20px 0">
      <el-pagination
        v-if="total > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { rTime, rPhone } from './js/filter'
export default {
  name: 'tableCom',
  props: ['data', 'header', 'total', 'loading'],
  filters: {
    fTime (time, timeFormat = 6) {
      if (!time) return
      return rTime(time, timeFormat)
    },
    fPhone (tel) {
      if (!tel) return
      return rPhone(tel)
    },
    fStatus (num, list, str = 'label') {
      for (const i of list) {
        if (i.value == num) { // 不要使用绝对等于
          return i[str]
        }
      }
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  mounted() {
    console.log(this.total)
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('changePageSize', val)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.$emit('changePage', val)
    }
  }
}
</script>

<style scoped>

</style>
