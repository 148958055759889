export const formList = [
  {
    label: '生码时间：',
    type: 'datetimerange',
    value: 'beginCreatedTime',
    placeholder: '请选择开始时间'
  },
  // {
  //   label: '',
  //   type: 'datetime',
  //   value: 'endCreatedTime',
  //   placeholder: '请选择结束时间'
  // },
  {
    type: 'radio',
    value: 'status',
    list: [
      {
        label: '全部',
        value: ''
      },
      {
        label: '未激活',
        value: 2
      },
      {
        label: '已激活',
        value: 4
      }
    ]
  },
  {
    type: 'button',
    position: 'margin-left: 20px',
    list: [
      {
        type: 'primary',
        text: '筛选',
        event: 'screen',
        args: true
      }
    ]
  },
  {
    type: 'icons',
    position: 'float:right',
    icon: 'el-icon-question',
    event: 'conrtolTip',
    style: 'color: #ccc'
  },
  {
    type: 'button',
    position: 'float:right',
    list: [
      {
        type: 'primary',
        text: '二维码生成',
        event: 'createQrCode'
      }
    ]
  }
]
export const flyform = [
  // {
  //   label: '生码批次:',
  //   isRequired: true,
  //   value: 'caption',
  //   type: 'input'
  // },
  {
    label: '生码数量:',
    isRequired: true,
    value: 'qty',
    type: 'number',
    placeholder: '仅限输入数字',
    min: 1,
    afterText: '个'
  },
  {
    label: '是否需要验证码:',
    isRequired: true,
    value: 'needVerifyCode',
    type: 'radio',
    list: [
      {
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 0
      }
    ]
  },
  {
    type: 'notes',
    according: 'needVerifyCode',
    content: {
      1: '注：需要验证码即扫了二维码进入验真页面需要输入验证码才能出验真结果，当开启防伪验真服务时有输入验证码页面和验真结果页面。',
      0: '注：不需要验证码即扫了二维码直接出验真结果，当开启防伪验真服务时只有验真结果页面'
    }
  },
  {
    type: 'button',
    list: [
      {
        type: 'primary',
        text: '生 码',
        event: 'createQrCode',
        args: true
      }
    ]
  }
]
export const tableHeader = [
  {
    label: '生码批次流水号',
    prop: 'caption',
    type: 'null'
  },
  {
    label: '订单号',
    prop: 'batchNo',
    type: 'null'
  },
  {
    label: '数量',
    prop: 'qty',
    type: 'null'
  },
  {
    label: '生码时间',
    prop: 'createdTime',
    type: 'null'
  },
  {
    label: '验证码',
    prop: 'needVerifyCode',
    type: 'status',
    handle: true,
    width: '70px',
    list: [
      {
        label: '否',
        value: false
      }, {
        label: '是',
        value: true
      }
    ]
  },
  {
    label: '防伪验真',
    prop: 'ifOpenVerify',
    type: 'status',
    isLink: true,
    width: '80px',
    event: 'verificat',
    list: [
      {
        label: '未配置',
        value: false
      }, {
        label: '已开启',
        value: true
      }
    ]
  },
  {
    label: '营销活动',
    prop: 'ifOpenActivity',
    type: 'status',
    event: 'active',
    width: '80px',
    isLink: true,
    list: [
      {
        label: '未配置',
        value: false
      }, {
        label: '已配置',
        value: true
      }
    ]
  },
  {
    label: '生码状态',
    prop: 'workStatus',
    type: 'progress',
    field: 'allProgress',
    width: '80px',
  },
  {
    label: '激活状态',
    prop: 'status',
    type: 'status',
    width: '80px',
    isLabel: true,
    list: [
      {
        label: '生码中',
        type: '',
        value: 1
      },
      {
        label: '未激活',
        type: 'danger',
        value: 2
      },
      {
        label: '激活中',
        type: 'warning',
        value: 3
      },
      {
        label: '已激活',
        type: 'success',
        value: 4
      },
      {
        label: '取消激活中',
        type: 'info',
        value: 5
      },
      {
        label: '生码失败',
        type: 'danger',
        value: 6
      }
    ]
  },
  {
    label: '激活时间',
    prop: 'activateBeginTime',
    type: 'null'
  },
  {
    label: '操作',
    type: 'control',
    width: '200px',
    list: [
      {
        text: '激活',
        mold: 'primary',
        size: 'mini',
        event: 'activation',
        exclude: true,
        field: 'status',
        value: [2]
      }, {
        text: '取消激活',
        mold: 'warning',
        size: 'mini',
        event: 'cancelActivation',
        exclude: true,
        field: 'status',
        value: [4]
      }, {
        text: '下载码包',
        mold: 'success',
        size: 'mini',
        event: 'download',
        exclude: true,
        field: 'status',
        value: [2,3,4,5]
      }
    ]
  }
]
