var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("formCom", {
        attrs: { formList: _vm.formList, inline: true },
        on: {
          conrtolTip: _vm.conrtolTip,
          screen: _vm.queryQrCode,
          createQrCode: function($event) {
            _vm.createQRCodeDialog = true
          }
        }
      }),
      _c("tableCom", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          header: _vm.tableHeader,
          total: _vm.total
        },
        on: {
          "update:data": function($event) {
            _vm.data = $event
          },
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:total": function($event) {
            _vm.total = $event
          },
          verificat: _vm.verificat,
          active: _vm.active,
          activation: _vm.activation,
          cancelActivation: _vm.cancelActivation,
          download: _vm.download,
          changePageSize: _vm.changePageSize,
          changePage: _vm.changePage
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "二维码生成",
            visible: _vm.createQRCodeDialog,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.createQRCodeDialog = $event
            }
          }
        },
        [
          _c("formCom", {
            attrs: { formList: _vm.flyform },
            on: { createQrCode: _vm.createQRCode }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "操作提示", visible: _vm.controlTip, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.controlTip = $event
            }
          }
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { "max-width": "100%" },
              attrs: {
                src: require("@/assets/img/OwnWorkbench/control.png"),
                alt: ""
              }
            })
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center"
              },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-link", { attrs: { type: "primary" } }, [
                _vm._v("看不明白？点击前往帮助文档")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.controlTip = false
                    }
                  }
                },
                [_vm._v("我已知晓")]
              ),
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.changeTip },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                },
                [_vm._v("不再提示")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.showDialog,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _vm.isVerify
            ? [
                _c(
                  "div",
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-text":
                          "已选中" +
                          (_vm.currentLine.needVerifyCode ? "" : "不") +
                          "需要验证码",
                        "inactive-text": _vm.openVerify ? "开启" : "未开启"
                      },
                      on: { change: _vm.changeVerify },
                      model: {
                        value: _vm.openVerify,
                        callback: function($$v) {
                          _vm.openVerify = $$v
                        },
                        expression: "openVerify"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "white-space": "nowrap",
                      display: "flex",
                      "justify-content": "center"
                    }
                  },
                  [
                    _vm.currentLine.needVerifyCode
                      ? _c("img", {
                          staticStyle: { width: "32%", "margin-left": "1%" },
                          attrs: {
                            src: require("@/assets/img/OwnWorkbench/verify.png"),
                            alt: ""
                          }
                        })
                      : _vm._e(),
                    _c("img", {
                      staticStyle: { width: "32%", "margin-left": "1%" },
                      attrs: {
                        src: require("@/assets/img/OwnWorkbench/verify_first.png"),
                        alt: ""
                      }
                    }),
                    _c("img", {
                      staticStyle: { width: "32%", "margin-left": "1%" },
                      attrs: {
                        src: require("@/assets/img/OwnWorkbench/verify_nofirst.png"),
                        alt: ""
                      }
                    })
                  ]
                )
              ]
            : [
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v("我的活动")
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "activity-create",
                        staticStyle: { "margin-right": "20px" },
                        on: { click: _vm.toCreateActive }
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                        _c("p", [_vm._v("创建活动")])
                      ]
                    ),
                    _vm._l(_vm.activeList, function(item, index) {
                      return [
                        item.id == _vm.currentLine.activityId
                          ? [
                              _c("active", {
                                key: index,
                                attrs: {
                                  item: item,
                                  id: _vm.currentLine.activityId
                                },
                                on: {
                                  queryActive: _vm.queryActive,
                                  relation: _vm.relation
                                }
                              })
                            ]
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _vm._v("我创建的活动")
                ]),
                _vm.activeList.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.activeList, function(item, index) {
                          return [
                            _c("active", {
                              key: index,
                              attrs: {
                                item: item,
                                id: _vm.currentLine.activityId || 1
                              },
                              on: {
                                queryActive: _vm.queryActive,
                                relation: _vm.relation
                              }
                            })
                          ]
                        })
                      ],
                      2
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "130px" } },
                        [
                          _c("listNull", {
                            attrs: { text: "你还没有创建活动呢~" }
                          })
                        ],
                        1
                      )
                    ])
              ],
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showBagList, title: "请选择需要下载的码包" },
          on: {
            "update:visible": function($event) {
              _vm.showBagList = $event
            }
          }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.downloadRadio,
                callback: function($$v) {
                  _vm.downloadRadio = $$v
                },
                expression: "downloadRadio"
              }
            },
            [
              _vm._l(_vm.bagList, function(item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticStyle: { "line-height": "50px" } },
                    [
                      _c("el-radio", { attrs: { label: item.id } }, [
                        _vm._v(
                          " 生码量：" +
                            _vm._s(item.workQty) +
                            "， 生码时间：" +
                            _vm._s(item.createdTime) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadEvent }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showBagList = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }