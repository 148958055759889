<template>
  <el-form :inline="inline" :model="form" label-width="140px">
    <template v-for="(item, index) of formList">
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="item.type === 'input' && form[item.depend] === item.dependValue"
      >
        <el-input
          v-model="form[item.value]"
          style="width: 250px"
          :placeholder="item.placeholder"
        >
          <template v-if="item.prepend" slot="prepend">{{
            item.prepend
          }}</template>
          <el-button
            v-if="item.append"
            slot="append"
            @click="$emit('openSelectUse')"
            >{{ item.append }}</el-button
          >
        </el-input>
        {{ item.afterText }}
      </el-form-item>
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="item.type === 'number' && form[item.depend] === item.dependValue"
      >
        <el-input
          v-model="form[item.value]"
          oninput="value=value.replace(/[^\d]/g,'')"
          style="width: 250px"
          :placeholder="item.placeholder"
        >
          <template v-if="item.prepend" slot="prepend">{{
            item.prepend
          }}</template>
          <el-button
            v-if="item.append"
            slot="append"
            @click="$emit('openSelectUse')"
            >{{ item.append }}</el-button
          >
        </el-input>
        {{ item.afterText }}
      </el-form-item>
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="
          item.type === 'textarea' && form[item.depend] === item.dependValue
        "
      >
        <el-input
          type="textarea"
          :rows="item.rows"
          style="width: 450px"
          :placeholder="item.placeholder"
          v-model="form[item.value]"
        >
        </el-input>
      </el-form-item>
      <!-- <el-form-item :label="item.label" :required="item.isRequired" v-if="item.type === 'tinymce' && form[item.depend] === item.dependValue">
        <Tinymce
          ref="ue"
          v-model="form[item.value]"
          :height="200"
          :width="900"
          text="请输入内容"
        />
      </el-form-item> -->
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="item.type === 'radio' && form[item.depend] === item.dependValue"
      >
        <el-radio-group v-model="form[item.value]">
          <template v-for="(radio, index) of item.list">
            <el-radio :key="index" :label="radio.value" :value="radio.value">{{
              radio.label
            }}</el-radio>
            <el-tag :key="index" v-if="radio.after" type="info">{{ radio.after }}</el-tag>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="
          item.type === 'datetimerange' &&
          form[item.depend] === item.dependValue
        "
      >
        <el-date-picker
          v-model="form[item.value]"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00', '23:59:59']"
          :placeholder="item.placeholder"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="
          item.type === 'datetime' && form[item.depend] === item.dependValue
        "
      >
        <el-date-picker
          v-model="form[item.value]"
          type="datetime"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          :placeholder="item.placeholder"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :key="index"
        :label="item.label"
        :required="item.isRequired"
        v-if="item.type === 'upload' && form[item.depend] === item.dependValue"
      >
        <el-upload
          class="upload-demo"
          :action="url"
          :before-upload="beforeAvatarUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          list-type="picture"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">{{ item.upload }}</div>
        </el-upload>
      </el-form-item>
      <el-form-item :key="index" v-if="item.type === 'button'" :style="item.position">
        <el-button
          v-for="(btn, index) of item.list"
          :key="index"
          :type="btn.type"
          @click="$emit(btn.event, btn.args ? form : '')"
          >{{ btn.text }}</el-button
        >
      </el-form-item>
      <el-form-item :key="index" v-if="item.type === 'notes'">
        {{ item.content[form[item.according]] }}
      </el-form-item>
      <el-form-item :key="index" v-if="item.type === 'icons'" :style="item.position">
        <i
          :class="item.icon"
          :style="item.style"
          style="font-size: 22px; cursor: pointer"
          @click="$emit(item.event)"
        ></i>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
// import Tinymce from "@/components/Tinymce";
export default {
  name: 'formCom',
  props: ['formList', 'inline'],
  // components: { Tinymce },
  data () {
    return {
      form: {
        needVerifyCode: 1,
        status: ''
      },
      // url: this.$http.adornUrl(`/sys/oss/upload?token=${this.$cookie.get('token')}`),
      fileList: []
    }
  },
  methods: {
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    beforeAvatarUpload (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      const isJPG = file.type === 'image/jpeg'
      if (!isLt1M) {
        this.$message.error('上传图片大小不能超过1MB!')
      }
      if (!isJPG) {
        this.$message.error('上传图片格式是jpg/jpeg/png!')
      }
      // 核心代码部分
      const isSize = new Promise(function (resolve, reject) {
        const width = 150
        const height = 150
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          console.log(img.width, img.height)
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        this.$message.error('banner尺寸必须是150*150!')
        return Promise.reject()
      })
      return isLt1M && isJPG && isSize
    }
  }
}
</script>

<style scoped>
</style>
